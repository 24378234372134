import React from "react"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  fullText: {
    height: "100vh",
    width: "100%",
    overflow: "hidden",
    textAlign: "center",
    alignItems: "center",
    padding: "20%",
    [theme.breakpoints.down("sm")]: {
      padding: "35% 0%",
    },
  },
  para1: {
    fontSize: "0.8em",
    fontFamily: '"Noto Sans KR", sans-serif',
    color: "#4e4e4e",
    letterSpacing: "-0.03em",
  },
  textContainerFirst: {
    padding: "1% 18%",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "35% 5% 5% 5%",
    },
  },
  textContainer: {
    padding: "5% 20% 5% 20%",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "10% 5% 5% 5%",
    },
  },
  normalPara: {
    fontSize: "0.9em",
    fontFamily: '"Noto Sans KR", sans-serif',
    whiteSpace: "pre-wrap",
    color: "#4e4e4e",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.75em",
    },
  },
  normalParaRight: {
    fontSize: "0.9em",
    textAlign: "right",
    fontFamily: '"Nanum Gothic Coding", monospace',
    whiteSpace: "pre-wrap",
    color: "#4e4e4e",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.6em",
    },
  },
  spanText: {
    fontSize: "0.7em",
    color: "rgba(255, 255, 255, 0.8)",
    fontFamily: '"Muli", sans-serif',
    letterSpacing: "0.5em",
    textTransform: "uppercase",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.5em",
    },
  },
}))

export const CenterTextBlock = () => {
  const classes = useStyles()
  const mainIntroText = [
    {
      id: 1,
      title: "trendy",
      desc: "무드에 맞는 트렌디한 디자인을 선보입니다.",
    },
    {
      id: 2,
      title: "reasonable pricing",
      desc: "커스텀이 가능한 합리적인 플랜을 제시합니다.",
    },
    {
      id: 3,
      title: "communicative",
      desc: "고객의 입장에서 먼저 생각합니다.",
    },
  ]

  return (
    <Grid container>
      <Grid className={classes.fullText} item xs={12} sm={12}>
        {mainIntroText.map(item => (
          <Grid item key={item.id} xs={12} sm={12}>
            <h3>'{item.title}'</h3>
            <p className={classes.para1}>{item.desc} </p>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export const NormalTextBlockLeft = props => {
  const classes = useStyles()

  return (
    <Grid container className={classes.textContainerFirst}>
      <p className={classes.spanText}>{props.title}</p>
      <Grid item xs={12} className={classes.normalPara}>
        {props.contents}
      </Grid>
    </Grid>
  )
}

export const NormalTextBlockRight = props => {
  const classes = useStyles()

  return (
    <Grid container className={classes.textContainer}>
      <Grid item xs={12} className={classes.normalParaRight}>
        {props.contents}
      </Grid>
    </Grid>
  )
}
