import React, { useRef, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Grid, CssBaseline, LinearProgress } from "@material-ui/core"
import gsap from "gsap"
import { Power3 } from "gsap/gsap-core"
import { Logo } from "../components/logo"
import "../styles/component.css"

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    width: "100%",
    height: "100vh",
    backgroundColor: "#000000",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0,
  },
  itemWrapper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "rgba(255, 255, 255, 0.5)",
    textAlign: "center",
  },
  itemDesc: {
    fontSize: "1em",
    opacity: 0,
    marginTop: "30px",
  },
}))

const Loading = () => {
  const classes = useStyles()
  const backBody = useRef(null)
  const itemDesc = useRef(null)

  useEffect(() => {
    // console.log(backBody.current)
    // console.log(itemDesc.current)
    gsap.to(backBody.current, 1, {
      opacity: 1,
      ease: Power3.easeOut,
      delay: 0.4,
    })
    gsap.to(itemDesc.current, 0.8, {
      opacity: 1,
      y: -20,
      ease: Power3.easeOut,
      delay: 1,
    })
  }, [])

  return (
    <>
      <CssBaseline />
      <Grid container className={classes.container} ref={backBody}>
        <Grid item sm={12} className={classes.itemWrapper}>
          <Logo fill={"#fff"} width={"100%"} />
          <LinearProgress />
          <p className={classes.itemDesc} ref={itemDesc}>
            L o a d i n g
          </p>
        </Grid>
      </Grid>
    </>
  )
}

export default Loading
