import React, { useState, useEffect } from "react"
import Loading from "../components/loading"
import { FullBg, FullBgContainer, HalfBg } from "../components/imgBackground"
import { CenterTextBlock } from "../components/textBlock"
import { ThemeProvider, CssBaseline } from "@material-ui/core"
import MobileMenuBar from "../components/mobileHeader"
import theme from "../styles/Theme"
import FullPage, {
  FullPageSections,
  FullpageSection,
  FullpageNavigation,
} from "@ap.cx/react-fullpage"
import SEO from "../components/seo"

export default ({ data }) => {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 2500)
  }, [])

  return (
    <>
      <SEO />
      {isLoading ? (
        <Loading />
      ) : (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <MobileMenuBar />
          <FullPage>
            <FullBgContainer>
              <FullPageSections>
                <FullpageSection>
                  <FullBg
                    src={"https://wedit.kr/app/maumleage/img/magan/main1.jpg"}
                    alt={"메이다이닝 야외결혼식"}
                  />
                </FullpageSection>
                <FullpageSection>
                  <FullBg
                    src={"https://wedit.kr/app/maumleage/img/magan/main2.jpg"}
                    alt={"에덴파라다이스호텔 웨딩"}
                  />
                </FullpageSection>
                <FullpageSection>
                  <CenterTextBlock />
                </FullpageSection>
                <FullpageSection>
                  <HalfBg
                    src={"https://wedit.kr/app/maumleage/img/magan/main3.jpg"}
                    alt={"아델라한옥 웨딩"}
                  />
                </FullpageSection>
                <FullpageSection>
                  <FullBg
                    src={"https://wedit.kr/app/maumleage/img/magan/main4.jpg"}
                    alt={"힐하우스웨딩 웨딩부케"}
                  />
                </FullpageSection>
                <FullpageSection>
                  <FullBg
                    src={"https://wedit.kr/app/maumleage/img/magan/main5.jpg"}
                    name={"에덴파라다이스호텔 웨딩아치"}
                  />
                </FullpageSection>
              </FullPageSections>
            </FullBgContainer>
            <FullpageNavigation />
          </FullPage>
        </ThemeProvider>
      )}
    </>
  )
}

// export const query = graphql`
//   {
//     main1: file(relativePath: { eq: "indexMain1.jpg" }) {
//       childImageSharp {
//         fluid(maxWidth: 1000, quality: 95) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//     main2: file(relativePath: { eq: "indexMain2.jpg" }) {
//       childImageSharp {
//         fluid(maxWidth: 1000, quality: 95) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//     main3: file(relativePath: { eq: "indexMain3.jpg" }) {
//       childImageSharp {
//         fluid(maxWidth: 700, quality: 95) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//     main4: file(relativePath: { eq: "indexMain4.jpg" }) {
//       childImageSharp {
//         fluid(maxWidth: 1000, quality: 95) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//     main5: file(relativePath: { eq: "indexMain5.jpg" }) {
//       childImageSharp {
//         fluid(maxWidth: 1000, quality: 100) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `
